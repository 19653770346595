export async function storeFile(file, options = {}) {
  const response = await noteFormsFetch(
    options.signedStorageUrl || 'vapor/signed-storage-url',
    {
      method: 'POST',
      body: options.data,
      bucket: options.bucket || '',
      content_type: options.contentType || file.type,
      expires: options.expires || '',
      visibility: options.visibility || '',
      baseURL: options.baseURL || null,
      headers: options.headers || {},
      ...options.options,
    },
  )

  // Upload to S3
  await useFetch(response.url, {
    method: 'PUT',
    body: file,
  })

  response.extension = file.name.split('.').pop()

  return response
}

/**
 * Retro compatibility post-Nuxt migration for file URLs
 * @param url
 * @returns {*}
 */
export function migratedAssetUrl (url) {
  [
    'https://noteforms.com/api',
    'https://notionforms.io/api'
  ].forEach((apiUrl) => {
    url = url.replace(apiUrl, 'https://api.noteforms.com')
  })
  return url
}
